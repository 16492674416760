<script>
  export let searchTerm = "";
</script>

<style>
  .search {
    width: 100%;
    text-align: center;
    height: 40px;
  }
</style>

<div class="search">
  <input bind:value={searchTerm} />
  (--SEARCH--)
</div>
