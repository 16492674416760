<script>
  import Modal from "../../ui/Modal.svelte";
  import PlaceEditor from "../../ui/TranslationsEditor.svelte";

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import { createResource, updateResource, deleteResource } from "../../_api";

  import { user } from "../../stores";
  export let venue = {};
  let modifiedVenue = {};
  let venueEditorZIndex = 10003;
  let venueEditorWidth = 505;

  const dispatchHideVenueEditor = () => {
    dispatch("hideVenueEditor");
  };
  const cloneVenue = () => {
    modifiedVenue.properties.id = "newVenue";
  };
  const resetVenue = () => {
    modifiedVenue = JSON.parse(JSON.stringify(venue));
  };
  const handleSaveVenueClicked = async () => {
    let response;
    if (modifiedVenue.properties.id === "newVenue") {
      response = await createResource("venue", modifiedVenue, $user.token);
      dispatch("venueCreated", response);
    } else {
      response = await updateResource(
        "venue",
        modifiedVenue.properties.id,
        modifiedVenue,
        $user.token
      );
      dispatch("venueUpdated", response);
    }
    dispatchHideVenueEditor();
  };
  const handleDeleteVenueClicked = async () => {
    const response = await deleteResource(
      "venue",
      venue.properties.id,
      $user.token
    );
    dispatch("venueDeleted", response);
    dispatchHideVenueEditor();
  };

  $: {
    venue;
    resetVenue();
  }
</script>

<style>
  .body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: justify;
    background-color: beige;
    margin: 5px 0px;
  }
  .section {
    width: 100%;
  }
  label {
    display: inline-block;
    width: 40%;
  }
  input {
    size: 40;
    width: 55%;
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }
  .filler {
    width: 100%;
  }
</style>

<Modal
  on:hideModalRequested={dispatchHideVenueEditor}
  modalBackgroundZIndex={venueEditorZIndex}
  modalWidth={venueEditorWidth}>
  <!-- Editor Header -->
  <div class="body">
    <div class="section">
      <h2>Editing venue</h2>
    </div>
    <hr />

    <!-- Editor Body -->
    <div class="section">*** General info ***</div>
    <label>Title:</label>
    <input bind:value={modifiedVenue.properties.title} />

    <div class="section">*** Location ***</div>
    <label>Longitude:</label>
    <input bind:value={modifiedVenue.geometry.coordinates[0]} />
    <label>Latitude:</label>
    <input bind:value={modifiedVenue.geometry.coordinates[1]} />
    <label>Country:</label>
    <input bind:value={modifiedVenue.properties.country} />
    <label>Place:</label>
    <PlaceEditor bind:item={modifiedVenue.properties.place} />
    <!-- <input bind:value={modifiedVenue.properties.place} /> -->
    <label>Address:</label>
    <input bind:value={modifiedVenue.properties.address} />

    <div class="section">*** Admin ***</div>
    <label>Venue active</label>
    <input type="checkbox" bind:checked={modifiedVenue.properties.isActive} />
  </div>
  <hr />
  <div class="footer">
    <!-- Editor Footer -->
    <button on:click={handleSaveVenueClicked}>(--SAVE--)</button>
    <button on:click={resetVenue}>(--UNDO--)</button>
    <div class="filler" />
    {#if modifiedVenue.properties.id !== 'newVenue'}
      <button on:click={cloneVenue}>(--CLONE--)</button>
      <button on:click={handleDeleteVenueClicked}>(--DELETE--)</button>
    {/if}
  </div>
</Modal>
