<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let modalWidth;

  const handleHideModalClicked = () => {
    dispatch("hideModalRequested");
  };
</script>

<style>
  .background {
    position: fixed;
    top: var(--navbar-ht);
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
  }
  .background .frame {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 5px;
  }
  .background .frame .header {
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    margin-bottom: 5px;
  }
  .background .frame .header .exit-button {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
  .background .frame .header .exit-button img {
    object-fit: contain;
  }
  .background .frame .slot {
    flex: 1 0 auto;
    height: 1%; /* ovog staviš samo da imaš neku visinu, onda flex riješi da je taman kolko treba */
    overflow: auto;
  }
</style>

<div class="background">
  <div class="frame" style="width: min(100%, {modalWidth}px);">
    <div class="header">
      <div class="exit-button" on:click={handleHideModalClicked}>
        <img src="icons/close.svg" alt="close modal" />
      </div>
    </div>
    <div class="slot">
      <slot />
    </div>
  </div>
</div>
