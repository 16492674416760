<script>
  import VenueEditor from "../VenueEditor/index.svelte";

  import { createEventDispatcher, getContext } from "svelte";
  const dispatch = createEventDispatcher();

  const { user } = getContext("venuesBrowserContext");
  export let venue = {};
  export let isSelected = false;
  let venueEditorShown = false;

  const dispatchVenueSelected = () => {
    dispatch("venueSelected", venue);
  };
  const hideVenueEditor = () => {
    venueEditorShown = false;
  };
  const showVenueEditor = () => {
    venueEditorShown = true;
  };
</script>

<style>
  div {
    padding: 1px;
    border: 1px solid black;
    margin: 1px;
    border-radius: 6px;
    font-size: 10px;
  }

  .kartica {
    height: 120px;
    width: 150px;
    margin: 2px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }

  .isSelected {
    background-color: rgb(255, 254, 199);
  }

  .kartica-zaglavlje {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
  }

  .editBtn:hover {
    cursor: pointer;
  }

  .kartica-sadrzaj {
    width: 100%;
    height: calc(100% - 78px);
  }

  .kartica-podnozje {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
</style>

<!-- Uređivač priredbi -->
{#if venueEditorShown}
  <VenueEditor
    {venue}
    on:hideVenueEditor={hideVenueEditor}
    on:venueCreated
    on:venueUpdated
    on:venueDeleted />
{/if}

<!-- Kartica za prikaz podataka o borilištu -->
<div class="kartica" class:isSelected on:click={dispatchVenueSelected}>
  <div class="kartica-zaglavlje">
    <div>
      {venue.properties.place[venue.properties.place.pp] + ' (' + venue.properties.country + ')'}
    </div>
    {#if user.role === 'admin'}
      <div class="editBtn" on:click={showVenueEditor}>--EDIT--</div>
    {/if}
  </div>
  <div class="kartica-sadrzaj">
    <h3>{venue.properties.title}</h3>
  </div>
  <div class="kartica-podnozje" />
</div>
