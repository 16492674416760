<script>
  import VenueCard from "./VenueCard.svelte";

  import { getContext } from "svelte";

  const { user } = getContext("venuesBrowserContext");
  export let venues = [];
  export let selectedVenue = {};
</script>

<style>
  .frame {
    border: 1px solid black;
    padding: 2px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
</style>

<div class="frame">
  {#if venues.length > 0}
    {#each venues as venue}
      <VenueCard
        {venue}
        isSelected={selectedVenue.properties && venue.properties.id === selectedVenue.properties.id}
        on:venueSelected
        on:venueCreated
        on:venueUpdated
        on:venueDeleted />
    {/each}
  {:else}"No venue matches the set criteria"{/if}
</div>
