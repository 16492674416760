<script>
  import { assoc, dissoc } from "ramda/src/";

  export let item;
  let newItemTranslationLanguage = "";
  let newItemTraslation = "";

  const addItemTranslation = () => {
    item = assoc(newItemTranslationLanguage, newItemTraslation, item);
    newItemTranslationLanguage = "";
    newItemTraslation = "";
  };
  const removeItemTranslation = pp => {
    item = dissoc(pp, item);
  };
</script>

<style>
  .frame {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .frame .item {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 2px;
  }
  .frame .item .item-editor {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 12px;
    margin-top: 2px;
  }
  .frame .item .item-editor .action-button {
    flex: 0 0 auto;
    margin-right: 12px;
    height: 20px;
    width: 20px;
  }
  .frame .item .item-editor .action-button img {
    width: 100%;
    object-fit: contain;
  }
  .frame .item .item-editor .description {
    flex: 1 0 auto;
    font-size: 16px;
    text-align: left;
    margin-right: 2px;
  }
  .frame .item .item-editor .input-value {
    flex: 0 1 auto;
  }
  .frame .item .item-editor .input-value input {
    font-size: 16px;
    background-color: lightsteelblue;
  }
</style>

<div class="frame">
  <div class="item">
    <div class="item-editor">
      <div class="description">Default language:</div>
      <div class="input-value">
        <input bind:value={item.pp} size="1" />
      </div>
    </div>
  </div>
  {#each Object.entries(item) as pp}
    {#if pp[0] !== 'pp'}
      <div class="item">
        <div class="item-editor">
          <div
            class="action-button"
            on:click={() => removeItemTranslation(pp[0])}>
            <img src="icons/remove.png" />
          </div>
          <div class="description">Language:</div>
          <div class="input-value">
            <input disabled value={pp[0]} size="1" />
          </div>
        </div>
        <div class="item-editor">
          <div class="description">Translation:</div>
          <div class="input-value">
            <input bind:value={item[pp[0]]} size="25" />
          </div>
        </div>
      </div>
    {/if}
  {/each}
  <div class="item">
    <div class="item-editor">
      <div class="action-button" on:click={addItemTranslation}>
        <img src="icons/add.png" />
      </div>
      <div class="description">Language:</div>
      <div class="input-value">
        <input bind:value={newItemTranslationLanguage} size="1" />
      </div>
    </div>
    <div class="item-editor">
      <div class="description">Translation:</div>
      <div class="input-value">
        <input bind:value={newItemTraslation} size="25" />
      </div>
    </div>
  </div>
</div>
